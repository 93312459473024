.container {
  // flex: 1;
  width: 160px;
  label {
    color: #191919;
  }
  select {
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    height: 40px;
    cursor: pointer;
  }
}
