.container {
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 10px;
  background-color: #f9fbfc;
  cursor: pointer;
  &:hover {
    background-color: #ecf0f4;
  }
  h4 {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;
  }
  .emailInfo {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
