.switch {
  width: 60px;
  height: 30px;
  border: 2px solid #e7e7e7;
  border-radius: 25px;
  background-color: #eee;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &.on {
    background-color: #4caf50;
  }

  &.off {
    background-color: #ccc;
  }
}

.toggle {
  width: 22px;
  height: 22px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.3s ease;

  // Pozycje przełącznika dla stanu "on" i "off"
  .off & {
    left: 3px;
  }

  .on & {
    left: 30px;
  }
}
