.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  cursor: pointer;
  .title {
    padding-right: 3px;
  }
  .sortingDirection {
    display: flex;
    flex-direction: column;
    p {
      font-size: 12px;
      line-height: 10px;
    }
  }
}
