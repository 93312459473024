.container {
  flex: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  .editor {
    min-height: 200px; /* Ustaw minimalną wysokość */
    border: 1px solid #ccc; /* Dodaj obramowanie, aby edytor był widoczny */
    padding: 10px; /* Dodaj wewnętrzne odstępy */
    margin-bottom: 10px;
    margin-top: 20px;
    background-color: #fff; /* Białe tło */
    border-radius: 4px;
    min-height: 315px;
  }
  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
