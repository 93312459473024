.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3vw 3vw;
  .form {
    padding: 60px 40px;
    width: 90%;
    background-color: #fafafa;
    max-width: 500px;
    text-align: center;
    h2 {
      padding: 0 0 27px 0;
      text-align: center;
    }
    p {
      padding: 5px 0;
      text-align: center;
    }
    button {
      margin: 30px 0;
      padding: 8px 20px;
      background: #151414;
      border-radius: 2px;
      width: fit-content;
      font-weight: 700;
      font-size: 14px;
      color: #ffffff;
    }
    .inputs {
      padding-bottom: 10px;
      text-align: left;
    }
    span.errors {
      font-size: 12px;
      padding: 3px;
      color: red;
    }
    a {
      font-weight: bold;
      &:hover {
        color: #0096ea;
      }
    }
  }
  .confirm {
    margin-top: 60px;
    padding: 60px 40px;
    text-align: center;
    background-color: #ffffff;
  }
}
