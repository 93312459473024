.container {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  width: 100vw;
  background-color: #f9fbfc;
  .logo {
    position: absolute;
    top: 24px;
    left: 20px;
    font-weight: bold;
    font-size: 24px;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
  .container {
    width: 290px;
    .logo {
      top: 40px;
      left: 40px;
    }
  }
}

@media screen and (min-width: 1440px) {
}
