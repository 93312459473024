.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  margin-top: 40px;
  background-color: #f9fbfc;
  border-radius: 8px;
}

.message {
  padding-bottom: 40px;
  text-align: center;
}

.progressBar {
  appearance: none;
}

.progressBar::-webkit-progress-bar {
  width: 300px;
  height: 10px;
  border-radius: 3px;
  background-color: #c4c4c4;
}

.progressBar::-webkit-progress-value {
  border-radius: 3px;
  background-color: #151414;
}

.camera {
  margin-top: 20px;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
