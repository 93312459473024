.container {
  .headerTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 24px;
      font-weight: 400;
      line-height: 40px;
    }
  }

  .controls {
    margin-top: 15px;
    margin-bottom: 40px;
    .search {
      border: 1px solid #d6d6d6;
      border-radius: 4px;
      height: 40px;
      width: 100%;
    }
    .filters {
      display: flex;
      gap: 20px;
    }
  }
  .createFirstGallery {
    padding: 60px 0 120px 0;
    background-color: #f9fbfc;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
    .createGalleryHeader {
      font-size: 22px;
      font-weight: normal;
    }
  }
}

@media screen and (min-width: 768px) {
  .container {
    .controls {
      display: flex;
      align-items: end;
      gap: 20px;
      .search {
        flex: 2;
        max-width: 400px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1440px) {
}
