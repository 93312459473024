.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000a9;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  .modal {
    padding: 10px;
    background-color: #fff;
    width: 60vw;
    height: 40vh;
    border-radius: 3px;
    .controls {
      display: flex;
      justify-content: space-between;
      .closeButton {
        cursor: pointer;
      }
    }
    .main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      height: 100%;
      p {
        text-align: center;
      }
      .downloadButton {
        display: block;
        background-color: #0096ea;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        width: 80px;
        height: 30px;
        border-radius: 3px;
        text-align: center;
        line-height: 30px;
      }
    }
  }
}
