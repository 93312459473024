.container {
  // padding: 40px 0;
  .header {
    .returnButton {
      display: flex;
      justify-content: space-between;
    }
    scrollbar {
      display: none;
    }
    .title {
      padding-top: 20px;
      h3 {
        font-size: 24px;
        font-weight: 400;
        padding-bottom: 10px;
        line-height: 30px;
      }
    }
    .arrowBack {
      display: none;
      @media (min-width: 600px) {
        display: block;
      }
    }
  }
  .controls {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 0;
    .chosenShots {
      margin-right: 10px;
      min-width: 115px;
    }
    .totalPrice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 10px;
      .icon {
        position: relative;
        cursor: pointer;
        margin-left: 10px;
        &:hover {
          .detailedPriceInfo {
            display: block;
          }
        }
        &:active {
          .detailedPriceInfo {
            display: block;
          }
        }
        .detailedPriceInfo {
          display: none;
          position: absolute;
          width: 300px;
          top: -97px;
          right: 28px;
          padding: 20px;
          background: white;
          box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.15);
          z-index: 999;
        }
      }
    }
    .filter {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 12px;
      background-color: #e5e5e5;
      width: 130px;
      line-height: 18px;
      .filterOptions {
        position: absolute;
        left: 0;
        bottom: -172px;
        width: 130px;
        background-color: #e5e5e5;
        z-index: 999;
        ul {
          padding: 10px;
          li {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  .gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .tabs {
      margin-bottom: 20px;
      display: flex;
      border-bottom: 1px solid #dbdbdb;
      .tab {
        cursor: pointer;
        width: 160px;
        font-size: 14px;
        text-align: center;
        line-height: 40px;
      }
    }
    .photo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 99;
      margin: 0 10px 20px 0;
      width: 100%;
      max-width: 360px;
      height: 420px;
      background: #ffffff;
      box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.15);
      .selectPhoto {
        position: absolute;
        bottom: 10px;
        left: 10px;
        width: 100px;
        color: #151414;
        font-size: 14px;
        display: block;
        padding: 5px;
      }
      a {
        text-align: center;
        img {
          // object-fit: cover;
          // object-position: center;
          width: 90%;
          cursor: pointer;
          max-height: 340px;
        }
      }
      .openPrintings {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 14px;
        width: 115px;
        height: 30px;
        background-color: #efefef;
      }
      .downloadPhoto {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 14px;
        width: 115px;
        height: 30px;
        background-color: #efefef;
      }
    }
    .edited {
      background: #b6d6ff;
    }
  }
}
