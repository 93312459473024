.alert {
  position: fixed;
  top: 80px;
  left: calc(50% - 210px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  width: 420px;
  z-index: 9999;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  .closeButton {
    cursor: pointer;
    border-radius: 8px;

    :hover {
      background-color: #f4f4f44b;
    }
  }
}
