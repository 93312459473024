.previewContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(34, 34, 34, 0.7);
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;

  .preview {
    position: relative;
    background-color: #fff;
    min-width: 400px;
    min-height: 200px;
    padding: 40px;
    .close {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 10px;
      cursor: pointer;
    }
  }
}
