.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 3vw;
  form {
    width: 90%;
    max-width: 500px;
    text-align: center;
    background-color: #fff;
    padding: 60px 40px;
    .header {
      padding: 0 0 27px 0;
      text-align: center;
      a {
        font-size: 12px;
      }
    }
    p {
      padding: 5px 0;
      text-align: center;
    }
    button {
      margin: 30px 0;
      padding: 8px 20px;
      background: #151414;
      border-radius: 2px;
      width: fit-content;
      font-weight: 700;
      font-size: 14px;
      color: #ffffff;
    }
    .input {
      text-align: left;
      input {
        background-color: #e9e9e9;
      }
    }
  }
}
