.container {
  flex: 1;
  width: 50%;
  .paymentsIntegration {
    flex: 1;
    .form {
      padding-top: 20px;
      .buttonsContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
