.container {
  .controls {
    display: flex;
    justify-content: space-between;
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      span {
        text-transform: uppercase;
        cursor: pointer;
        font-size: 14px;
        &:hover {
          color: #0096ea;
        }
      }
      .active {
        color: #0096ea;
      }
      .numbersContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
    .selectContainer {
      select {
        cursor: pointer;
      }
    }
  }
}
