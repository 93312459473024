.container {
  .controls {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
    padding: 20px 0;
  }
  .profile {
    max-width: 500px;
    .notes {
      textarea {
        min-height: 150px;
      }
    }
  }
  .actionButtons {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    .controls {
      //   display: flex;
      //   justify-content: end;
      //   align-items: center;
      //   gap: 20px;
      //   padding: 20px 0;
    }
    .profile {
      .secondary {
        display: flex;
        justify-content: space-between;
        gap: 20px;
      }
      .notes {
        textarea {
          //   min-height: 150px;
        }
      }
    }
    .actionButtons {
      //   display: flex;
      //   justify-content: end;
      //   margin-top: 20px;
    }
  }
}
