.container {
  .tabs {
    margin-top: 20px;
    display: flex;
    border-bottom: 1px solid #dbdbdb;
    .tab {
      cursor: pointer;
      width: 120px;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
    }
  }
}
