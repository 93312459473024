.container {
  margin-top: 20px;
  max-width: 500px;
  .resumeTable {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .item {
      display: flex;
      align-items: center;
      padding: 10px;
      border: 1px solid #e1f4fe;
      border-radius: 8px;
      min-height: 50px;
      &:nth-child(odd) {
        background-color: #f9fbfc;
      }
      p {
        max-width: 400px;
      }
    }
    .totalPriceItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      .questionMarkIcon {
        cursor: pointer;
      }
    }
    .printingsItem {
      .printingsTable {
        .printItem {
          display: flex;
          align-items: center;
          gap: 20px;
          padding: 10px;
          border-bottom: 2px solid #0098f030;
        }
      }
    }
  }
}
