.container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  background-color: #f9fbfc;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: self-end;
  //   gap: 20px;
  .printing {
    width: 45%;
    .printingLabel {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 3px;
      p {
        font-size: 12px;
      }
    }
    .printingControls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
    }
  }

  .printing.disabled {
    display: none;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
