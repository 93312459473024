.container {
  .titleHeader {
    padding: 20px 0;
    h3 {
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .emptyList {
    padding: 60px 0 60px 0;
    background-color: #f9fbfc;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@media screen and (min-width: 1024px) {
  // position: relative;
  .returnButton {
    position: absolute;
    top: 40px;
  }
}
