.container {
  .header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 0 0 40px 0;
    .helloHeader {
      h3 {
        font-size: 22px;
        padding-bottom: 10px;
        font-weight: normal;
      }
    }
  }

  .createFirstGallery {
    padding: 60px 0 120px 0;
    background-color: #f9fbfc;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
    .createGalleryHeader {
      font-size: 22px;
      font-weight: normal;
    }
  }

  .zeroPendingGalleries {
    .infoHeader {
      font-size: 18px;
      font-weight: 600;
    }
    .infoBox {
      display: flex;
      height: 300px;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      background-color: #f9fbfc;
      span {
        display: block;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
      }
    }
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1440px) {
}
