.container {
  cursor: pointer;
  //   height: 420px;
  width: 100%;
  max-width: 360px;
  min-height: 440px;
  padding: 20px;
  background-color: #f9fbfc;
  border-radius: 8px;

  .cover {
    height: 320px;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
  h4 {
    padding-top: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    padding-bottom: 10px;
  }
  &:hover {
    background-color: #ecf0f4;
  }
}
