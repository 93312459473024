div.input span {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
}

textarea:invalid[focused="true"] {
  border: 1px solid red;
}

textarea:invalid[focused="true"] ~ span {
  display: block;
}
