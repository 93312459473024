.container {
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: #f9fbfc;
  .topMenu {
    .returnButton {
      // display: flex;
      // align-items: center;
      // cursor: pointer;
      // width: 120px;
    }
  }
  .confirmCard {
    border-radius: 3px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 6px 7px 9px -1px rgba(0, 0, 0, 0.03);
    border: 3px solid rgba(0, 0, 0, 0.03);
    width: 400px;
    height: 600px;
    .cardHeader {
      padding-bottom: 10px;
      h6 {
        font-weight: 600;
        font-size: 18px;
      }
    }
    ul {
      li {
        padding: 10px 0;
        border-bottom: 1px solid rgb(229, 229, 229);
        span {
          font-size: 12px;
        }
      }
    }
  }
  .buttons {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .confirmOrder {
      background-color: #0096ea;
      line-height: 40px;
      font-weight: 500;
      border-radius: 3px;
      color: #fff;
    }
    .declineOrder {
      background-color: #424242;
      line-height: 30px;
      font-size: 14px;
      border-radius: 3px;
      color: #fff;
    }
  }
}
