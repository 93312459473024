.inputContainer {
  position: relative;
  color: red;
  margin-bottom: 5px;

  .labelContainer {
    position: relative;
    .eyeIcon {
      position: absolute;
      right: 10px;
      bottom: -35px;
      color: #191919;
      cursor: pointer;
    }
  }

  span {
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
  }

  .dot {
    display: inline;
    color: #ec0000;
  }

  input:invalid[focused="true"] {
    border: 1px solid red;
  }

  input:invalid[focused="true"] ~ span {
    display: block;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
