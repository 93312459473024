@import "../../../../style//variables.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 3vw;
  .loginForm {
    padding: 60px 40px;
    width: 90%;
    max-width: 500px;
    text-align: center;
    background-color: #fafafa;
    .header {
      padding: 0 0 27px 0;
      text-align: center;
      .toggleButtons {
        padding-top: 10px;
        height: 40px;
        display: flex;
        gap: 10px;
        .toggleButton {
          flex: 1;
          font-size: 12px;
          text-transform: uppercase;
          line-height: 30px;
          background-color: #f0f0f0;
          cursor: pointer;
        }
      }
    }
    p {
      padding: 5px 0;
      text-align: center;
    }
    .loginButton {
      margin-top: 20px;
      background: $secondaryColor;
      border-radius: 2px;
      width: 120px;
      height: 40px;
      font-weight: 700;
      font-size: 14px;
      color: #ffffff;
      transition: 0.1s linear;
      &:hover {
        background: $primaryColor;
      }
    }
    .input {
      position: relative;
      text-align: left;
      .eyeIcon {
        position: absolute;
        right: 10px;
        bottom: 15px;
        cursor: pointer;
      }
      input {
        background: #e9e9e9;
      }
    }
  }
}

// --> LOADING DOTS <-- //
.loadingContainer {
  position: relative;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadingContainer div {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: black;
  float: left;
  margin: 0 3px;
  background: #fff;
}
.loadingContainer .ball1 {
  z-index: 1;
  animation: bounce 2s infinite ease-in-out;
}
.loadingContainer .ball2 {
  animation: bounce 2s infinite ease-in-out;
  animation-delay: 0.25s;
}
.loadingContainer .ball3 {
  animation: bounce 2s infinite ease-in-out;
  animation-delay: 0.5s;
}

@keyframes bounce {
  0%,
  15% {
    -moz-transform: translate(0, 0);
  }
  50% {
    -moz-transform: translate(0, -10px);
  }
  85%,
  100% {
    -moz-transform: translate(0, 0);
  }
}
@keyframes bounce {
  0%,
  20% {
    -webkit-transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, -10px);
  }
  80%,
  100% {
    -webkit-transform: translate(0, 0);
  }
}
