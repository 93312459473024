.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  max-width: 90vw;
  padding: 20px 0;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.15);
}

.photo img {
  max-height: 100%;
  width: 100%;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: #fff;
  padding: 10px;
}

button.selectPhoto {
  width: 100px;
  color: #151414;
  font-size: 14px;
  display: block;
  padding: 5px;
  margin-right: 10px;
}

button.openPrintings {
  font-size: 14px;
  width: 110px;
  height: 30px;
  z-index: 999;
  background-color: #efefef;
  margin-right: 10px;
}

button.downloadPhoto {
  font-size: 14px;
  width: 115px;
  height: 30px;
  background-color: #efefef;
}

.chosenShots {
  margin-right: 10px;
}

.totalPrice {
  margin-right: 10px;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
