.container {
  padding: 20px 3vw;
  .actualizationDate {
    text-align: center;
    padding-bottom: 20px;
  }
  h2,
  h6 {
    text-align: center;
    margin: 10px 0;
  }
  ol {
    padding: 20px;
  }
}
