@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700;900&display=swap");
@import "./variables.scss";

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1440px) {
}

*,
h6 {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  // background: #e9e9e9;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="email"],
textarea,
select {
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  width: 100%;
}

button {
  border: none;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="tel"],
input[type="email"],
select {
  margin: 5px 0 5px 0;
  height: 40px;
  padding: 0 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
}

// select:invalid {   Zakomentowalem poniewaz w wyborze typu sesji opcje zmienily kolor a powinny byc czarne
//   color: #adadad;
// }

input::placeholder {
  color: #adadad;
}

input[type="date"]:invalid {
  color: #adadad;
}

textarea {
  padding: 10px;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  &:hover {
    color: inherit;
  }
}
span {
  // font-weight: bold;
}

p,
span,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $secondaryColor;
  margin: 0;
}

label {
  font-size: 12px;
  font-weight: 600;
  color: #575757;
}

p {
  font-size: 14px;
  margin: 0;
  line-height: 17px;
}

h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
}

h3 {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 2px;
  line-height: 20px;
}

h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}

.row {
  --bs-gutter-x: 0;
}
