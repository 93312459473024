.container {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); // Ustawiamy szerokość kolumn
  // gap: 20px; // Odstęp między elementami
  // flex-wrap: wrap;
  // justify-content: space-between;
  // //   padding: 10px;
  // gap: 20px;

  display: grid;
  grid-template-columns: 1fr; // Elastyczne kolumny
  gap: 20px; // Odstęp między kolumnami
  width: 100%; // Zapewnia, że siatka zajmuje pełną szerokość strony
  padding: 10px;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 1240px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
