.container {
  .tabs {
    margin-top: 20px;
    display: flex;
    border-bottom: 1px solid #dbdbdb;
    .tab {
      cursor: pointer;
      width: 160px;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
    }
  }
  .gallery {
    padding-top: 20px;
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: end;
      .filtersAndSorters {
        display: flex;
        gap: 20px;
        align-items: end;
        margin-bottom: 10px;
        .sorter {
          margin-bottom: 15px;
        }
        .deleteAllPhotos {
          margin-bottom: 15px;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .photoCounter {
        margin-bottom: 25px;
      }
    }
    .addFirstPhotos {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 40px;
      p {
        width: 350px;
        line-height: 24px;
      }
    }
  }
  .actionMenu {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-top: 20px;
    .deleteButton {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }
  }
}
