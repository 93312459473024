.container {
  .headerTitle {
    margin-top: 20px;
    margin-bottom: 20px;
    h3 {
      font-size: 24px;
      font-weight: 400;
      line-height: 40px;
    }
  }
  .form {
    max-width: 500px;
    .secondary {
      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        input {
          flex: 1;
        }
      }
    }
    .actionButtons {
      display: flex;
      justify-content: end;
      margin-top: 20px;
    }
  }
}
