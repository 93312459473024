.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: hidden;

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    padding: 10px;
    cursor: pointer;
  }

  .photo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    max-width: 90vw;
    padding: 20px 0;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.15);
    img {
      max-height: 100%;
      width: 100%;
    }
  }
}

.arrows {
  display: none;
}

@media screen and (min-width: 800px) {
  .arrows {
    display: block;
  }
  .arrowLeft {
    position: absolute;
    left: 20px;
    top: 45%;
    cursor: pointer;
  }
  .arrowRight {
    position: absolute;
    right: 20px;
    top: 45%;
    cursor: pointer;
  }
}
