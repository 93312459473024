.container {
  position: relative;
  background-color: #f9fbfc;
  border-radius: 8px;
  cursor: pointer;
  .settingsButton {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s ease;
    border-radius: 8px;
    padding: 10px 20px;
    &:hover {
      background-color: #ecf0f4;
    }
    .userAvatar {
      width: 40px;
      height: 40px;
      margin-right: 5px;
      background-color: #fff;
      border-radius: 8px;
      img {
        object-fit: cover;
        width: 40px;
        height: 40px;
        border-radius: 8px;
      }
    }
    .userInfo {
      .userOrganization {
        font-size: 12px;
        padding-top: 3px;
      }
    }
  }
  .userMenu {
    position: absolute;
    bottom: -47px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ecf0f4;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
    height: 50px;
    .separator {
      height: 1px;
      width: 210px;
      background-color: #fff;
    }
    ul {
      width: 100%;
      background-color: #ecf0f4;
      li {
        display: flex;
        gap: 20px;
        border-radius: 8px;
        margin: 10px 20px;
        padding: 5px 10px;
        font-size: 14px;
        transition: 0.3s ease;
        &:hover {
          background-color: #f9fbfc;
        }
        p {
          line-height: 20px;
        }
      }
    }
  }
}
