.container {
  .headerTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 24px;
      font-weight: 400;
      line-height: 40px;
    }
  }

  .controls {
    margin-top: 15px;
    margin-bottom: 40px;
    .filters {
      .search {
        max-width: 400px;
      }
    }
  }

  .createFirstCustomer {
    padding: 60px 10px 120px 10px;
    background-color: #f9fbfc;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
    .createCustomerHeader {
      font-size: 22px;
      font-weight: normal;
    }
  }

  // .emptyList {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   background: #e8e8e8;
  //   height: 100px;
  //   cursor: pointer;
  // }

  // .controls {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   padding: 20px 0;
  //   .button {
  //     height: 30px;
  //     width: 100px;
  //     background: #151414;
  //     color: #ececec;
  //     font-size: 12px;
  //     font-weight: 600;
  //     border-radius: 2px;
  //   }
  //   .filters {
  //     display: flex;
  //     gap: 10px;
  //     .search {
  //       border: 1px solid #e9e9e9;
  //       height: 30px;
  //       width: 300px;
  //     }
  //   }
  // }
}
