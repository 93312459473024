.selectContainer {
  // width: fit-content;
  margin-bottom: 5px;
  width: 100%;
  .errorMessage {
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
  }

  .dot {
    display: inline;
    color: #ec0000;
  }

  select:invalid[focused="true"] {
    border: 1px solid red;
  }

  select:invalid[focused="true"] ~ span {
    display: block;
  }

  select:disabled {
    background-color: #efefef4d;
  }

  .select {
    position: relative;
    .icon {
      position: absolute;
      top: 12px;
      left: 10px;
    }
  }
}
