.container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: minmax(240px, auto);
  grid-column: span 1;
  grid-row: span 1;
  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1260px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
