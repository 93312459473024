.container {
  .headerTitle {
    margin-bottom: 20px;
    h3 {
      font-size: 24px;
      font-weight: 400;
      line-height: 40px;
    }
  }
  .orders {
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    overflow-x: auto;
    table {
      width: 100%;
      border-collapse: collapse;
      background-color: white;
      border-radius: 10px;
      overflow: hidden;
      th,
      td {
        padding: 12px 20px;
        text-align: left;
        white-space: nowrap;
      }
      th {
        background-color: #f3f4f6;
        font-weight: 600;
        color: #4b5563;
      }
      tr {
        border-bottom: 1px solid #e5e7eb;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: #f9fafb;
        }
      }

      td {
        color: #4b5563;
        font-size: 14px;
        vertical-align: middle;
      }
    }
  }
}
// }

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1440px) {
}
