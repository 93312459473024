.photo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 240px;
  // box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.15);
  background-color: #f9fbfc;
  padding: 20px;

  a {
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;
    max-height: 85%;
    // margin: 20px 0;
    img {
      width: 100%;
      height: 90%;
      object-fit: cover;
    }
  }
  .controlsBox {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 20px;
    z-index: 99;
    .photoSettingsIcon {
      cursor: pointer;
    }
    .deleteInput {
      width: 20px;
      height: 20px;
      cursor: pointer;
      visibility: visible;
    }
  }
  .photoSettings {
    position: absolute;
    top: 0;
    left: 0;
    padding: 60px 20px;
    width: 100%;
    height: 100%;
    background-color: #f9fbfc;
    .settingsList {
      .setting {
        font-size: 14px;
        cursor: pointer;
        &:hover {
          font-weight: 500;
        }
      }
    }
  }
}
.selected {
  background: #cdffb250;
}
.edited {
  background: #b6d6ff50;
}
.marked {
  background: #fffda150;
}
