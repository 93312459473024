.container {
  position: relative;
  padding: 20px;
  transition: transform 0.3s;
  background-color: #f9fbfc;
  box-shadow: 1px 2px 6px 0px #0098f017;
  width: 100%; // Zapewnia, że każde dziecko zajmuje pełną szerokość dostępnej kolumny siatki

  .thumbnail {
    width: 100%;
    cursor: pointer;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      height: 250px;
    }
  }

  .controls {
    padding: 20px 0 0 0;

    .buttons {
      display: flex;
      justify-content: space-between;
    }
  }

  .spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
