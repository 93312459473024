.container {
  .newGalleryHeader {
    padding-bottom: 40px;
    h3 {
      font-size: 24px;
      font-weight: 400;
      padding-bottom: 10px;
    }
  }

  .newGalleryForm {
    padding-bottom: 20px;
    .data {
      display: flex;
      flex-direction: column;
      .primaryData {
        flex: 2;
        border-bottom: 1px solid #dedede;
        padding-bottom: 40px;
        margin-bottom: 40px;
        .selectClient {
          display: flex;
          justify-content: space-between;
          align-items: end;

          span {
            display: none !important;
          }
          .addClientButton {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 9px 10px;
            width: 146px;
            margin: 5px 0px 10px 20px;
            border: 1px solid #0096ea;
            border-radius: 4px;
            p {
              color: #0096ea;
            }
          }
        }
        .selectDateAndType {
          display: flex;
          gap: 20px;
          .dateInput {
            flex: 1;
          }
          .typeSelect {
            flex: 1;
          }
        }
        .selectPrice {
          margin-top: 40px;
          display: flex;
          gap: 20px;
          .priceSelect {
            flex: 1;
          }
          .paidSelect {
            flex: 1;
          }
        }
        .selectShots {
          display: flex;
          gap: 20px;
          .shotsSelect {
            flex: 1;
          }
          .addShotsSelect {
            flex: 1;
          }
        }
      }
      .printingsData {
        flex: 1;
        h4 {
          padding-bottom: 20px;
          font-size: 18px;
          font-weight: 400;
        }
        p {
          font-size: 12px;
        }
        .selectPrintings {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          padding-top: 40px;
          .printSelect {
            padding-bottom: 10px;
            width: 100px;
          }
        }
      }
    }
  }
  .confirmBox {
    margin-top: 40px;
    display: flex;
    justify-content: end;
    align-items: center;
    .confirmButton {
    }
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
  .container {
    .newGalleryForm {
      .data {
        flex-direction: row;
        .primaryData {
          border-right: 1px solid #dedede;
          border-bottom: none;
          padding-right: 40px;
          margin-bottom: 0;
        }
        .printingsData {
          padding-left: 40px;
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
}
