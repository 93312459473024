.container {
  .headerTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    @media screen and (min-width: 1024px) {
      padding-top: 0px;
    }
    h3 {
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      // padding-bottom: 20px;
    }
    .resumeSettings {
      position: relative;
      .resumeSettingsIcon {
        position: relative;
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    // position: relative;
    .returnButton {
      position: absolute;
      top: 40px;
    }
  }
}

// .container {
//   .titleHeader {
//     display: flex;
//     justify-content: space-between;
//     .pageDescription {
//       display: flex;
//     }
//     .resumeSettingsIcon {
//       cursor: pointer;
//     }
//   }

//   .summary {
//     padding: 20px 0;
//     p {
//       padding: 3px 0;
//     }
//     ul {
//       li {
//         padding: 2px 10px;
//         border-bottom: 2px solid #e8e8e8;
//         width: 180px;
//         display: flex;
//         justify-content: space-between;
//         flex-direction: row;
//         align-items: center;
//         p,
//         span {
//           display: block;
//         }
//       }
//     }

//     .confirmBox {
//       margin: 20px 0;
//       display: flex;
//       gap: 10px;
//       align-items: center;
//       .selectionsMenu {
//         display: flex;
//         gap: 10px;
//         .selectionButton {
//           width: 100px;
//         }
//       }

//       .confirmButton,
//       .selectionButton {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         width: 210px;
//         height: 40px;
//         background: #151414;
//         border-radius: 2px;
//         color: #fff;
//         font-weight: 700;
//         font-size: 14px;
//         cursor: pointer;
//       }
//     }
//   }

//   .selection {
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;

//     .selectedPhoto {
//       display: flex;
//       padding: 10px;
//       margin: 0 0 20px 0;
//       background: #e8e8e8;
//       width: 48%;
//       height: 170px;
//       min-width: 400px;
//       .cover {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: space-between;
//         width: 170px;
//         height: 110px;
//         img {
//           width: 100%;
//           max-height: 120px;
//         }
//         p {
//           padding-top: 3px;
//           text-align: center;
//         }
//       }

//       .printingsList {
//         .printing {
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           width: 160px;
//           border-bottom: 2px solid #151414;
//           p,
//           span {
//             padding: 0 10px;
//           }
//         }
//       }
//     }
//   }
// }
