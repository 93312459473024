.container {
  .mainControls {
    .headerTitle {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      padding-top: 20px;
      @media screen and (min-width: 1024px) {
        padding-top: 0px;
      }
      h3 {
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 20px;
      }
    }
    .links {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding-bottom: 40px;
      .date {
        display: flex;
        gap: 5px;
        align-items: center;
      }
      .customerProfile {
        cursor: pointer;
        display: flex;
        gap: 5px;
        align-items: center;
        p {
          color: #0099f0;
          font-weight: 500;
        }
      }
      a {
        color: #0099f0;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .actionButtons {
    display: flex;
    gap: 20px;
    justify-content: end;
  }
}
