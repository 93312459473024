.container {
  margin-bottom: 40px;
  .headerTitle {
    margin-bottom: 20px;
    h3 {
      font-size: 24px;
      font-weight: 400;
      line-height: 40px;
    }
  }
  form {
    max-width: 500px;
  }
  .actionButtons {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
}
