.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
  .menuTrigger {
    position: relative;
    z-index: 999999999;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
  .container {
    justify-content: end;
    .menuTrigger {
      display: none;
    }
  }
}

@media screen and (min-width: 1440px) {
}
