.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  width: 100vw;
  height: 100vh;

  .questionModal {
    width: 90%;
    max-width: 400px;
    background: #fff;
    padding: 40px;
  }

  .buttonsWrapper {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    button {
      padding: 5px 20px;
    }
    .positiveAction {
      background-color: #0099f0;
      color: #fff;
    }
  }
}
