.container {
  position: absolute;
  left: -210px;
  top: -10px;
  background-color: #fff;
  z-index: 999;
  width: 240px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px -8px rgba(0, 0, 0, 0.356);
  .closeIcon {
    position: absolute;
    right: 5px;
    top: 10px;
    cursor: pointer;
  }
  .settingsList {
    .setting {
      cursor: pointer;
      padding-top: 10px;
      color: #191919;
      :hover {
        color: #0099f0;
      }
    }
  }
}
