.container {
  padding: 0 3vw;
  background-color: #f9fbfc;
  .navbar {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content {
    background: #fff;
    // margin: 10px 10px 0 10px;
    min-height: calc(100vh - 60px);
    padding: 30px 15px;
    box-shadow: 6px 7px 9px -1px rgba(0, 0, 0, 0.03);
    border-radius: 2px;
    flex: 1;
  }
}
