.container {
  display: flex;
  margin-top: 20px;
  .avatarSettings {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-basis: 50%;
    .filePicker {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      p {
        max-width: 280px;
      }
      .avatarSelector {
        input[type="file"] {
          font-size: 14px;
          display: none;
        }
      }
    }
    .avatarPreview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 90%;
      gap: 20px;
      img {
        object-fit: cover;
        width: 400px;
        height: 400px;
        border-radius: 50%;
        background-color: rgb(218, 218, 218);
      }
      .button {
        width: 100px;
        padding: 5px 0;
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        border-radius: 2px;
        background-color: #0096ea;
      }
    }
  }
}
