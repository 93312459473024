.primaryButton {
  padding: 8.5px 12px;
  width: fit-content;
  min-width: 120px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}
