.container {
  display: flex;
  justify-content: end;
  background-color: #fff;
  .page {
    width: 100vw;
    display: flex;

    .pageContent {
      background: #fff;
      min-height: 100vh;
      padding: 10px;
      flex: 1;
    }
  }
}

@media screen and (min-width: 768px) {
  .container {
    .page {
      .pageContent {
        padding: 20px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .container {
    .page {
      width: calc(100vw - 305px);
      .pageContent {
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .container {
    .page {
      .pageContent {
        padding: 40px;
      }
    }
  }
}
