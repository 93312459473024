.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f9fbfc;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 10px;
  &:hover {
    background-color: #ecf0f4;
  }
  .primaryInfos {
    display: flex;
    gap: 10px;
    .cover {
      flex: 1;
      background-color: #dbdbdb;
      height: 160px;
      border-radius: 8px;
      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
    .primaryInfo {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      .title {
        font-size: 18px;
        line-height: 40px;
        font-weight: 500;
      }
      .secondaryInfos {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .secondaryInfo {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 5px;
          opacity: 0.8;
        }
      }
    }
  }
  .minorInfos {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .minorInfo {
      flex: 1;

      p {
        font-size: 14px;
        color: #19191990;

        span {
          padding-left: 5px;
          font-weight: 400;
        }
      }
    }
    .sessionStatus {
      flex: 0.5;
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
}

@media screen and (min-width: 768px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    gap: 20px;
    .primaryInfos {
      min-width: 400px;
      // max-width: 550px;
      .cover {
        height: 100px;
        min-width: 100px;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .primaryInfo {
        flex: auto;
        // display: flex;
        // flex-direction: column;
        justify-content: end;
        padding-bottom: 15px;
        // gap: 10px;
        .title {
          // font-size: 18px;
          // font-weight: 500;
        }
        .secondaryInfos {
          // display: flex;
          flex-direction: row;
          // gap: 10px;
          .secondaryInfo {
            // display: flex;
            // align-items: center;
            // justify-content: start;
            // gap: 5px;
            // opacity: 0.8;
          }
        }
      }
    }
    .minorInfos {
      // display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: end;
      gap: 5px;
      padding-bottom: 15px;
      .minorInfo {
        flex: auto;
        // display: flex;
        // flex-direction: column;
        // justify-content: start;
        // align-items: center;
        p {
          // font-size: 14px;
          // color: #19191990;

          span {
            padding-left: 0;
            // font-weight: 400;
          }
        }
      }
      .sessionStatus {
        flex: auto;
        display: flex;
        align-items: center;
        justify-content: end;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .container {
    // flex-direction: row;
    // gap: 0;
    .primaryInfos {
      // min-width: 400px;
      // max-width: 550px;
      .cover {
        // height: 100px;
        // width: 100px;
        img {
          // width: 100px;
          // height: 100px;
        }
      }
      .primaryInfo {
        // flex: auto;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // gap: 10px;
        .title {
          // font-size: 18px;
          // font-weight: 500;
        }
        .secondaryInfos {
          // display: flex;
          // flex-direction: row;
          // gap: 10px;
          .secondaryInfo {
            // display: flex;
            // align-items: center;
            // justify-content: start;
            // gap: 5px;
            // opacity: 0.8;
          }
        }
      }
    }
    .minorInfos {
      // display: flex;
      // flex-direction: row;
      // justify-content: end;
      // align-items: end;
      gap: 10px;
      // padding-bottom: 15px;
      .minorInfo {
        // flex: auto;
        // display: flex;
        // flex-direction: column;
        // justify-content: start;
        p {
          // font-size: 14px;
          // color: #19191990;

          span {
            // padding-left: 0;
            // font-weight: 400;
          }
        }
      }
      .sessionStatus {
        // flex: auto;
        // display: flex;
        // align-items: center;
        // justify-content: end;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
}

@media screen and (min-width: 1440px) {
  .container {
    // flex-direction: row;
    // gap: 0;
    .primaryInfos {
      // min-width: 400px;
      // max-width: 550px;
      .cover {
        // height: 100px;
        // width: 100px;
        img {
          // width: 100px;
          // height: 100px;
        }
      }
      .primaryInfo {
        // flex: auto;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // gap: 10px;
        .title {
          // font-size: 18px;
          // font-weight: 500;
        }
        .secondaryInfos {
          // display: flex;
          // flex-direction: row;
          // gap: 10px;
          .secondaryInfo {
            // display: flex;
            // align-items: center;
            // justify-content: start;
            // gap: 5px;
            // opacity: 0.8;
          }
        }
      }
    }
    .minorInfos {
      // display: flex;
      // flex-direction: row;
      // justify-content: end;
      // align-items: end;
      gap: 20px;
      // padding-bottom: 15px;
      .minorInfo {
        // flex: auto;
        // display: flex;
        // flex-direction: column;
        // justify-content: start;
        p {
          // font-size: 14px;
          // color: #19191990;

          span {
            // padding-left: 0;
            // font-weight: 400;
          }
        }
      }
      .sessionStatus {
        // flex: auto;
        // display: flex;
        // align-items: center;
        // justify-content: end;
      }
    }
  }
}
