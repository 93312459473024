.container {
  padding-top: 20px;
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      gap: 40px;
    }
    .primaryData {
      // margin-bottom: 40px;
      @media screen and (min-width: 768px) {
        width: 50%;
        border-bottom: none;
      }
    }
    .secondaryData {
      @media screen and (min-width: 768px) {
        width: 50%;
      }
      .buttons {
        display: flex;
        justify-content: end;
        padding-top: 20px;
      }
    }
  }
}
