.customerGalleryCarousel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  padding: 10px;
  cursor: pointer;
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  max-width: 90vw;
  padding: 20px 0;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.15);
}

.photo img {
  max-height: 100%;
  width: 100%;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

button.selectPhoto {
  width: 100px;
  color: #151414;
  font-size: 14px;
  display: block;
  padding: 5px;
  margin-right: 10px;
}

button.openPrintings {
  font-size: 14px;
  width: 110px;
  height: 30px;
  z-index: 999;
  background-color: #efefef;
  margin-right: 10px;
}

.chosenShots {
  margin-right: 10px;
}

.totalPrice {
  margin-right: 10px;
}

.arrows {
  display: none;
}

@media screen and (min-width: 800px) {
  .arrows {
    display: block;
  }
  .arrowLeft {
    position: absolute;
    left: 20px;
    top: 45%;
    cursor: pointer;
  }
  .arrowRight {
    position: absolute;
    right: 20px;
    top: 45%;
    cursor: pointer;
  }
}
