.container {
  margin-top: 120px;
  margin-bottom: 0;
  height: 100%;
  .list {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
    justify-content: space-between;
    height: 100%;
    .primaryLinks,
    .secondaryLinks {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .packagesLink {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        background-color: #ecf0f4;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 20px;
        .question {
          font-size: 10px;
          font-weight: normal;
          display: block;
        }
        a {
          padding: 9px 16px;
          width: 190px;
          height: 40px;
          border-radius: 4px;
          background-color: #0099f0;
          color: #fff;
          font-size: 14px;
          text-align: center;
        }
      }
    }
    .listItem {
      a {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 8px 16px;
        width: 220px;
        height: 40px;
        border-radius: 8px;
        &:active {
          background-color: #ecf0f4;
        }
      }
    }
  }
}
