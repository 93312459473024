.container {
  .tabs {
    margin-top: 20px;
    display: flex;
    border-bottom: 1px solid #dbdbdb;
    .tab {
      cursor: pointer;
      width: 110px;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
    }
  }

  .selection {
    display: flex;
    // flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;
    padding: 10px 0;
    .selectedPhotos {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-around;
      gap: 10px;
      .selectedPhoto {
        display: flex;
        gap: 20px;
        background-color: #f9fbfc;
        border-radius: 8px;
        max-width: 350px;
        padding: 10px;
        .photoPreview {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;
          .cover {
            width: 100px;
            height: 100px;
            a {
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
          .title {
          }
        }
        .selectedPrintings {
          .printing {
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 5px 10px 5px 10px;
            border-bottom: 2px solid #0098f030;
          }
        }
      }
    }
    .selectionControls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 5px;
      .downloadResumeFileButton {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        p {
          font-weight: 500;
          color: #0099f0;
        }
      }
    }
  }
}
